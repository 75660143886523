<template>
    <div class="storeDataInfo" id="storeDataInfo">
        <div class="title-wraps">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ name: 'accountManagement' }">账号管理</el-breadcrumb-item>
                <el-breadcrumb-item>门店数据<span>({{shopUser.nickname}})</span></el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="case-wrap">
            <div class="screen-wrap">
                <commonSelect
                    @changeDate="changeDate"
                    @clickTagDate="clickTagDate"
                ></commonSelect>
            </div>
            <div class="sw-wrap">
                <div class="sw-item">
                    <div class="select-wrap" v-if="user.kf_level == 1">
                        <el-select clearable  @change="selectChangeAuth" v-model="authStatus" placeholder="请选择审核状态">
                            <el-option
                                v-for="item in authOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="select-wrap">
                        <el-select clearable  @change="selectChangeBrand" v-model="epId" placeholder="请选择品牌筛选">
                            <el-option
                                v-for="item in brandOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="search-wrap">
                        <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                        <el-button @click="clickSearch" type="primary">搜索</el-button>
                    </div>
                </div>
                <div class="tag-wrap">
                    <div class="tw-item">
                        <div class="item" style="border: 1px solid #ddd;"></div>正常
                    </div>
                    <div class="tw-item">
                        <div class="item" style="background: #e6a23c"></div>即将过期
                    </div>
                    <div class="tw-item">
                        <div class="item" style="background: #f56c6c"></div>已过期
                    </div>
                    <div class="tw-item">
                        <div class="item" style="background: #dddddd"></div>被停用
                    </div>
                </div>
            </div>
            <el-table :data="tableData" border style="width: 100%" v-loading="loading" :row-class-name="tableRowClassName" class="table-wrap">
                <el-table-column align="center" prop="id" label="ID" width="60"></el-table-column>
                <el-table-column align="center" prop="enterprise_brand.name" label="品牌名称" width="120"></el-table-column>
                <el-table-column align="center" prop="name" label="门店名称" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="shop_owner.username" label="店长" width="80"></el-table-column>
                <el-table-column align="center" prop="shop_owner.mobile" label="联系电话" width="120"></el-table-column>
                <el-table-column align="center" prop="enterprise_shop_rel.remark_shop_address" label="备注地区" width="120" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column align="center" prop="user_count" width="90">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="根据子账号排序" placement="top">
                            <div class="sort-wrap" @click="changeShow('user_count')">
                                <span>子账号</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="customer_count" width="90">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="根据客户排序" placement="top">
                            <div class="sort-wrap" @click="changeShow('customer_count')">
                                <span>客户</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="customer_record_count" width="100">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="根据成交单量排序" placement="top">
                            <div class="sort-wrap" @click="changeShow('customer_record_count')">
                                <span>成交单量</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="browsing_records_count" width="90">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="根据曝光排序" placement="top">
                            <div class="sort-wrap" @click="changeShow('browsing_records_count')">
                                <span>曝光</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="comment_count" width="100">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="根据口碑积攒排序" placement="top">
                            <div class="sort-wrap" @click="changeShow('comment_count')">
                                <span>口碑积攒</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="vr_imgs_count" width="100">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="跟据全景设计排序" placement="top">
                            <div class="sort-wrap" @click="changeShow('vr_imgs_count')">
                                <span>全景设计</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center"  label="门店认证" width="90">
                    <template slot-scope="scope">
                        <div v-if="scope.row.shop_apply != null">
                            <div style="color: #999999;" v-if="scope.row.shop_apply.business_license_img_state == 0">未提交</div>
                            <div style="color: #f5bd00;" v-if="scope.row.shop_apply.business_license_img_state == 1">待审核</div>
                            <div style="color: #00d90d;" v-if="scope.row.shop_apply.business_license_img_state == 2">审核通过</div>
                            <div style="color: #ff0100;" v-if="scope.row.shop_apply.business_license_img_state == 3">审核不通过</div>
                        </div>
                        <div v-else style="color: #999999;">未提交</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="支付认证" width="90">
                    <template slot-scope="scope">
                        <div v-if="scope.row.shop_apply != null">
                            <div style="color: #999999;" v-if="scope.row.shop_apply.state == 0">未提交</div>
                            <div style="color: #f5bd00;" v-if="scope.row.shop_apply.state == 1">待审核</div>
                            <div style="color: #00d90d;" v-if="scope.row.shop_apply.state == 2">审核通过</div>
                            <div style="color: #ff0100;" v-if="scope.row.shop_apply.state == 3">认证不通过</div>
                        </div>
                        <div v-else style="color: #999999;">未提交</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="last_login_time" label="最后登录时间" width="110"></el-table-column>
                <el-table-column align="center" prop="adminuser_allocate_time" label="分配时间" width="100">
                    <template #header>
                        <el-tooltip class="item" effect="dark" content="跟据分配时间排序" placement="top">
                            <div class="sort-wrap" @click="changeShow('adminuser_allocate_time')">
                                <span>分配时间</span>
                                <i class="iconfont el-icon-caret-bottom"></i>
                            </div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" fixed="right" label="操作" :show-overflow-tooltip="true" width="80">
                    <template slot-scope="scope">
                        <div class="operation-wrap">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <i  @click="clickInfo(scope.row)" class="iconfont el-icon-document"></i>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="分配" placement="top">
                                <i v-if="user.kf_level == 1" @click="clickAllocation(scope.row)" class="iconfont el-icon-sort"></i>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-wrap">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-size="50"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog :visible.sync="isAllocation" 
            :before-close="handleClose"
            custom-class="dialog-wrap"
            :close-on-click-modal="false">
            <div class="title">{{title}}</div>
            <div class="content">
                <div class="tag">分配至：</div>
                <el-select clearable v-model="userId" placeholder="请选择负责人">
                    <el-option
                        v-for="item in userOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="btn-wrap">
                <el-button :loading="btnLoading" @click="submitAllocation" type="primary">确定</el-button>
            </div>
        </el-dialog>
        <el-backtop target=".storeDataInfo" :right="20" :bottom="30"></el-backtop>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import commonSelect from "@/components/commonSelect.vue"
export default {
    components: {
      commonSelect
    },
    data () {
        return {
            user: {},
            tableData: [],
            loading: false,
            currentPage: 1,
            total: 0,
            epId: '',
            keyword: '',
            dateType: '',
            startDate: '',
            endDate: '',
            sortBy: '',
            isAllocation: false,
            title: '',
            userOptions: [],
            brandOptions: [],
            userId: '',
            shopId: 0,
            btnLoading: false,
            shopUser: {},
            authOptions: [{
                value: 1,
                label: '门店认证未提交'
            },{
                value: 2,
                label: '门店认证待审核'
            },{
                value: 3,
                label: '门店认证审核通过'
            },{
                value: 4,
                label: '门店认证审核不通过'
            },{
                value: 5,
                label: '支付审核未提交'
            },{
                value: 6,
                label: '支付审核待审核'
            },{
                value: 7,
                label: '支付审核审核通过'
            },{
                value: 8,
                label: '支付审核审核不通过'
            }],
            authStatus: '',
            scroll: 0
        }
    },
    mounted () {
        this.user = JSON.parse(localStorage.getItem('userInfo'));
        this.shopUser = JSON.parse(localStorage.getItem('userid'))
        this.getShopList();
        this.getUserConditions();
        this.getEpConditions();
        this.$route.meta.keepAlive = true;
    },
    beforeRouteLeave(to, from, next) {
        //scroll要先在data里面赋值一个0 scroll:0
        //el-main是你这个页面的主体class名称，写你自己的名称。
        this.scroll = document.getElementById("storeDataInfo").scrollTop;
        next();
    },
    activated() {
        // this.getGroupImgList();
        this.$nextTick(() => {
            setTimeout(() => {
                document.getElementById("storeDataInfo").scrollTop = this.scroll;
            }, 0);
        });
    },
    methods: {
        // 判断
        tableRowClassName({row, rowIndex}){
             if(row.shop_status === 2){
                return 'warning-row';
            }else if(row.shop_status === 3){
                return 'danger-row';
            }else if(row.shop_status === 4){
                return 'info-row';
            }
        },
        // 跳转详情
        clickInfo(row){
            localStorage.removeItem('shop')
            this.$router.push({ name:'shopDataInfo'})
            let brand = {}
            brand.id = row.ep_id;
            brand.name = row.ep_name;
            localStorage.setItem('brand',JSON.stringify(brand));
            localStorage.setItem('shop',JSON.stringify(row));
            localStorage.setItem('jump',2);
        },
        // 确定分配
        submitAllocation(){
            if(this.userId == ''){
                this.$message({
                    message: '请选择负责人！',
                    type: 'warning'
                });
                return
            }
            let params = {
                shop_id: this.shopId,
                user_id: this.userId
            }
            this.btnLoading = true;
            common.connect("/customerservicev1/shop/allocation",params,(res)=>{
                if(res.isSuccess == 1){
                    this.getShopList();
                    this.isAllocation = false;
                    this.userId = '';
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.btnLoading = false;
                }
            });
        },
        // 分配弹窗
        clickAllocation(row){
            this.isAllocation = true;
            this.title = row.name;
            this.shopId = row.id;
        },
        handleClose(){
            this.isAllocation = false;
            this.userId = '';
        },
        // 日期选择
        changeDate(startDate,endDate){
          this.currentPage = 1;
          this.startDate = startDate.slice(0,11)+' 00:00:01';
          this.endDate = endDate.slice(0,11)+' 23:59:59';
          this.getShopList()
        },
        clickTagDate(item){
          this.dateType = item.label;
          this.currentPage = 1;
          if(item.label != 'date'){
            this.startDate = '';
            this.endDate = '';
            this.getShopList();
          }
        },
        // 点击排序
        changeShow(item){
            this.sortBy = item;
            this.getShopList();
        },
        selectChangeAuth(){
            this.getShopList();
        },
        // 品牌筛选
        selectChangeBrand(){
            this.getShopList();
        },
        // 搜索
        clickSearch(){
            this.getShopList();
        },
        // 获取品牌数据
        getEpConditions(){
            common.connect("/customerservicev1/enterprise_brand/EpConditions",{},(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.ep_id,
                    label: v.name
                }
                })
                this.brandOptions = resultSecond;
            });
        },
        // 获取负责人数据
        getUserConditions(){
            common.connect("/customerservicev1/admin/userConditions",{},(res)=>{
                let resultSecond =  res.data.map(v =>{
                return{
                    value: v.user_id,
                    label: v.nickname
                }
                })
                this.userOptions = resultSecond;
            });
        },
        // 获取列表数据
        getShopList(){
            this.loading = true;
            let params = {
                ep_id: this.epId,
                keyword: this.keyword,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                sort_by: this.sortBy,
                user_id: this.shopUser.id,
                page: this.currentPage,
                auth_status: this.authStatus
            }
            common.connect("/customerservicev1/shop/index",params,(res)=>{
                this.total = res.data.count;
                this.tableData = res.data.list;
                this.loading = false;
            });
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getShopList();
        }
    },
}
</script>

<style lang='scss'>
.storeDataInfo{
    height: calc(100vh - 60px);
    overflow-y: auto;
   .title-wraps{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 20px;
       .el-breadcrumb__item{
            .el-breadcrumb__inner{
                color: #000;
                font-weight: bold;
            }
            .is-link{
                color: #333;
                font-weight: 300;
            }
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px;
        border-radius: 6px;
        .sw-wrap{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .tag-wrap{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .tw-item{
                    font-size: 14px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-left: 10px;
                    .item{
                        margin-right: 5px;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        background: #fff;
                    }
                }
            }
        }
        .sw-item{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .select-wrap{
                margin-right: 20px;
            }
            .search-wrap{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin: 20px 0;
                .el-input{
                    width: 200px;
                    margin-right: 10px;
                }
            }
        }
    }
    .sort-wrap{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .operation-wrap{
        display: flex;
        justify-content: center;
        align-items: center;
        .iconfont{
            font-size: 16px;
            padding: 0 10px;
            cursor: pointer;
        }
        .el-icon-sort{
            transform: rotate(90deg);
        }
    }
    .pagination-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
          padding-top: 10px;
        margin-bottom: -10px;
    }
    .dialog-wrap{
        width: 500px;
        .title{
            display: flex;
            justify-content: center;
            font-size: 17px;
            color: #000;
            font-weight: bold;
            padding-top: 20px;
        }
        .content{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-left: 20px;
            padding: 30px 0;
            .tag{
                margin-right: 10px;
            }
            .el-input{
                width: 390px;
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: flex-end;
            margin-right: 25px;
            padding-bottom: 10px;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }

    .el-table tbody tr:hover>td {
        background-color: transparent !important;
    }
    .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf  {
        background-color: transparent !important;
    }
    .el-table__fixed-right tbody tr:hover>td {
        background-color: transparent !important;
    }
}
</style>
